@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
:root {
  --greenPrimary: #125621;
  --greenSecondary: #25883b;
  --orange: #fec604;
}

.table-header {
  font-size: small !important;
}

body {
  margin: 0;
  padding: 0;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  font-family: "Montserrat", sans-serif !important;
}

body::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.ul-unstyled {
  list-style-type: none;
}

.wraps {
  background-color: #ededeb;
}

.h100 {
  height: 100vh;
}

.text-orange {
  color: var(--orange);
}

.bg-orange {
  background-color: var(--orange);
  color: black;
}

#home-table-menu {
  background-color: var(--orange);
  color: black;
  padding: 5px !important;
}

#home-table-menu .badge {
  background-color: white;
  color: black;
}

#home-table-menu:hover {
  background-color: var(--greenSecondary);
  color: white;
}

#home-table-menu.active {
  background-color: var(--greenPrimary);
  color: white;
}

.text-green-primary {
  color: var(--greenPrimary);
}

.text-green-secondary {
  color: var(--greenSecondary);
}

.btn-primary {
  background-color: var(--orange) !important;
  color: black !important;
  border: none !important;
  outline: none !important;
  box-shadow: none;
  font-weight: bold !important;
}

/* #home-banner {
  height: 100vh;
  width: 100%;
  background-image: url("./assets/images/home-banner-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */

#home-nav {
  /* height: "9vh", position: "fixed", width: "100%", zIndex: 3, top: 0, left: 0, right: 0 */
  height: 8vh;
  position: fixed;
  width: 100%;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
}

#banner-icon-container {
  position: absolute;
}

#home-banner input {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
  outline: none !important;
  box-shadow: none;
}

.news-text-preview {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.articles:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.lined-text {
  border: 1px solid var(--greenPrimary);
}

/* Login */
.login-container {
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./assets/images/home-banner-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.login-inner-container {
  width: 25% !important;
}

#email-input {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none;
}

.ipt {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

#login-icon {
  height: 10vh;
}

/* dashboard */
#dashboard-wrapper {
  width: 100%;
  height: 100vh;
}

#sidebar {
  width: 20%;
  height: 100vh;
  background-color: var(--primary-color);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

#sidebar.active {
  width: 0;
}

.nav-link.active {
  /* background-color: #f5f5f5; */
  color: #f8b739 !important;
}


#sidebar-toggler {
  display: none;
}

#sidebar ul.components {
  padding: 0;
}

#sidebar ul li {
  font-size: 16px;
}

#sidebar ul li > ul {
  margin-left: 10px;
}

#sidebar ul li > ul li {
  font-size: 14px;
}

#sidebar ul li a {
  padding: 10px 0;
  display: block;
  color: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

#sidebar ul li span {
  padding: 10px 0;
  display: block;
  color: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

#sidebar ul li span:hover {
  color: #f8b739;
}

#sidebar ul li a:hover {
  color: #f8b739;
}

#sidebar ul li.active > a {
  background: transparent;
  color: #f8b739;
}

#closeSidebar {
  display: none;
}

a[data-bs-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

#content {
  width: 80%;
  height: 100%;
  max-height: 100vh;
  overflow: auto;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

#content.active {
  width: 100%;
}

/* switch button */
.switcher {
  width: 33px;
  height: 19px;
  border-radius: 30px;
  background-color: #ccc;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.toggle-btn {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd;
  margin: 2px;
  transition: all 0.3s;
  position: absolute;
}

.on .toggle-btn {
  transform: translateX(17px);
  background-color: white;
}

.switcher.on {
  background-color: var(--orange);
}

/* spinner */
.spinner-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* video player container */
.v-player-container {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./assets/images/v-player-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 50vh;
}

/* spinner */
#spinner {
  z-index: 100;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
}

@media (max-width: 600px) {
  #home-banner {
    height: auto;
    width: 100%;
    margin-top: 13vh;
  }

  #banner-icon-container {
    position: relative;
  }

  .login-inner-container {
    width: 70% !important;
  }

  #home-nav {
    height: 9vh;
  }

  #content {
    width: 100%;
  }

  #sidebar {
    width: 0;
  }

  #sidebar.active {
    width: 100%;
    position: absolute;
    z-index: 1;
  }

  #closeSidebar {
    display: block;
  }
}
